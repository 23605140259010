import React, {useEffect} from 'react';
import './App.css';
import {useState} from 'react';
import {BrowserRouter as Router, Routes, Route, HashRouter, useNavigate} from "react-router-dom"
import MainPage from "./MainPage";
import Wishlist from "./Wishlist";
import LampaFeed from "./LampaFeed/LampaFeed";
import Lvchk from "./Lvchk/Lvchk";
import PdfViewer from "./Resume/PdfViewer";
import DiscordUnblock from "./DiscordUnblock";

function App() {

    const navigate = useNavigate();

    useEffect(() => {
        // Проверяем хеш на наличие #discord и перенаправляем на /discord
        if (window.location.hash === "#discord") {
            navigate("/discord");
        }

        // Добавляем обработчик изменения хеша
        const handleHashChange = () => {
            if (window.location.hash === "#discord") {
                // Перенаправляем на путь /discord, если хеш #discord
                navigate("/discord");
            }
        };

        window.addEventListener("hashchange", handleHashChange);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, [navigate]);

  return (
      <div className="App">
          <div className="StickyTop">
              <a id="topLink" href="https://swathingsoap.com/"><img className="Logo-holder"
                                                             src="https://i.imgur.com/G5wQfJW.png" alt="logo"/></a>
              <hr className="Hr"></hr>
          </div>

          <Routes>
              <Route path="/wishlist" element={<Wishlist/>}/>
              <Route path="/lampafeed" element={<LampaFeed/>}/>
              <Route path="/lvchk" element={<Lvchk/>}/>
              <Route path="/resume" element={<PdfViewer pdfUrl="/resumeru.pdf"/>}/>
              <Route path="/discord" element={<DiscordUnblock />} />
              <Route path="/" element={<MainPage/>} />
          </Routes>





      </div>
  );
}

export function LoadingImage(){
    return(
        <div>
            <img className="Rot-img" src="https://i.imgur.com/G5wQfJW.png"
                 alt="logo"/>
        </div>
    )
}


export default App;
