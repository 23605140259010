import React, {Component} from "react";
import {Link} from "react-router-dom";
import Comments from "./Comments";
import {LoadingImage} from "./App";


class DiscordUnblock extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            downloadCount: 0,
        };
    }

    componentDidMount() {
        this.fetchDownloadCount();
        document.title = "Soap Discord Unblock";

        let link = document.querySelector("link[rel~='icon']");
        if(link != null) {
            link.setAttribute("href", "./discordicon.ico");
        }

        let top = document.querySelector(".Logo-holder");
        console.log(top)
        if(top != null) {
            top.setAttribute("src", "./discordicon.ico")
        }
    }

    fetchDownloadCount = async () => {
        try {
            const response = await fetch((process.env.REACT_APP_SPREADSHEET_GET as string) + "downloadCounter");
            const data = await response.text();
            this.setState({ downloadCount: parseInt(data) });
        } catch (error) {
            console.error('Ошибка при получении данных о количестве скачиваний:', error);
        }
    };

    handleDownload = async () => {
        try {
            // Инициируем скачивание файла
            window.location.href = '/SoapDiscordUnblock.rar';

            // Отправляем POST-запрос
            await fetch((process.env.REACT_APP_SPREADSHEET_GET as string) + "downloadCounter", {
                method: 'POST'
            });


        } catch (error) {
            console.error('Ошибка при отправке POST-запроса:', error);
        }
    };

    render() {
        return <>
            <header className="App-header">
                <p>Soap Discord Unblock</p>
                <br/> <img src="https://i.imgur.com/JuYzR2A.png"></img><br/> <br/> <br/>
                <p>Максимально простая в использовании программа для разблокировки доступа к Discord.</p>
                <p>Не проксирует ваше соединение. Работает и для голосовых каналов.</p>
                <p>Все что нужно, скачать архив, распаковать, запустить SoapDiscordUnblock.exe.</p>
                <p>По желанию можно добавить в автозапуск.</p>
                <p>Работает на основе <a target="_blank" rel="noreferrer" href="https://github.com/N3M1X10/zapret_build">zapret_build</a>, Но в удобном для пользователей формате</p>
                <br/>
                <div style={{marginTop: '0px'}}>
                    <button
                        onClick={this.handleDownload}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontFamily: 'inherit', // Наследовать шрифт от родительского элемента
                            fontSize: 'inherit',
                        }}
                    >
                        Скачать
                    </button>
                </div>
                <br/>
                <p>Количество скачиваний:</p>
                <p>{this.state.downloadCount == 0 ? <center><div className="loader"></div></center> : this.state.downloadCount}</p>
                <a target="_blank" rel="noreferrer" href="https://www.virustotal.com/gui/file/30f5054987ce414b19abe227600106e00faa9c8da750672d2bfa50df4d2c5994">Ссылка на Virustotal</a>
                <br/>(Онлайн проверка на вирусы)
                <p></p>
                <a target="_blank" rel="noreferrer" href="https://hipolink.me/swathingsoap">Донат</a>
                <br/>(Можете облагодарить донатом)
                <br/>
                <br/>
                <p>Если программа вам помогла, оставьте комментарий:</p>
                <br/>
                <div className="Comments">
                    <Comments type="discord"/>
                </div>
            </header>


        </>;
    }
}


export default DiscordUnblock